<template>
  <component :is="isAuthenticated ? 'LayoutManager' : 'LandingPageLayout'">
    <div
      :class="[
        'terms__conditions--container',
        { 'loggedin-terms': isAuthenticated },
        { container: !isAuthenticated }
      ]"
      id="terms-page"
    >
      <LxpBreadcrumb
        :items="breadcrumb1"
        @click="navigateToLink"
        id="top-breadcrumb"
        v-if="isAuthenticated"
      />
      <div class="mobile__nav--wrapper d-md-none" id="mobile-nav">
        <nav class="navbar heading__item--container">
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span>{{ currentItem }}</span>
                <img src="@/assets/images/landing/course/arrow.svg" alt="" />
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li v-for="item in navItems" :key="item.id">
                  <a
                    class="dropdown-item"
                    :class="{ active: currentItem === item.name }"
                    :href="`#${item.id}`"
                    @click.prevent="scrollToContent(item.id, item.name)"
                  >
                    {{ item.name }}
                  </a>
                  <ul v-if="item.subItems" class="subdropdown-menu">
                    <li v-for="subItem in item.subItems" :key="subItem.id">
                      <a
                        class="dropdown-item"
                        :class="{ active: currentItem === subItem.name }"
                        :href="`#${subItem.id}`"
                        @click.prevent="
                          scrollToContent(subItem.id, subItem.name)
                        "
                      >
                        {{ subItem.name }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <nav id="terms-list" class="heading__item--container d-none d-md-block">
        <nav class="nav">
          <div v-for="(item, index) in navItems" :key="item.id">
            <a
              class="nav-link no-active"
              :href="`#${item.id}`"
              @click.prevent="toggleMenu(item.id)"
            >
              {{ item.name }}
              <span>
                <img
                  src="@/assets/images/vle/chevron-blue.svg"
                  alt=""
                  :style="
                    isSubMenuOpen(item.id) ? 'transform: rotate(180deg);' : ''
                  "
                />
              </span>
            </a>
            <nav
              v-if="item.subItems"
              :class="['nav', { 'd-none': !isSubMenuOpen(item.id) }]"
            >
              <a
                v-for="(subItem, subIndex) in item.subItems"
                :class="['nav-link', { active: index === 0 && subIndex === 0 }]"
                :href="`#${subItem.id}`"
                :key="subItem.id"
                @click.prevent="scrollToContent(subItem.id, subItem.name)"
              >
                {{ subItem.name }}
              </a>
            </nav>
          </div>
        </nav>
      </nav>
      <div class="content__item--container">
        <div id="item1" class="content-item">
          <LxpBreadcrumb
            :items="breadcrumb1"
            @click="navigateToLink"
            v-if="!isAuthenticated"
          />
          <h1 class="main-heading">
            {{ $t("terms_conditions.category1") }}
          </h1>
          <div class="disclaimer-information">
            <img :src="disclaimerIcon" alt="disclaimer" />
            {{ $t("terms_conditions.disclaimers.content") }}
          </div>
          <h2 class="item-heading">
            {{ $t("terms_conditions.disclaimers.title") }}
          </h2>
          <div class="item-body">
            {{ $t("terms_conditions.disclaimers.heading") }}<br /><br />
            {{ $t("terms_conditions.disclaimers.information.content1")
            }}<br /><br />
            {{ $t("terms_conditions.disclaimers.information.content2")
            }}<br /><br />
            {{ $t("terms_conditions.disclaimers.information.content3")
            }}<br /><br />
            {{ $t("terms_conditions.disclaimers.information.content4")
            }}<br /><br />
            {{ $t("terms_conditions.disclaimers.information.content5")
            }}<br /><br />
            {{ $t("terms_conditions.disclaimers.information.content6")
            }}<br /><br />
            {{ $t("terms_conditions.disclaimers.information.content7")
            }}<br /><br />
            {{ $t("terms_conditions.disclaimers.information.content8")
            }}<br /><br />
            {{ $t("terms_conditions.disclaimers.information.content9")
            }}<br /><br />
            {{ $t("terms_conditions.disclaimers.information.content10")
            }}<br /><br />
            {{ $t("terms_conditions.disclaimers.information.content11") }}
          </div>
        </div>
        <div id="item2" class="content-item">
          <h1 class="main-heading">
            {{ $t("terms_conditions.category2") }}
          </h1>
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.1.heading") }}
          </h2>
          <div class="item-body">
            <i18next path="terms_conditions.table_contents.1.content1">
              <b>
                {{ $t("terms_conditions.table_contents.1.content1_0") }}
              </b> </i18next
            ><br /><br />
            {{ $t("terms_conditions.table_contents.1.content2") }}<br /><br />
            {{ $t("terms_conditions.table_contents.1.content3") }}<br /><br />
            {{ $t("terms_conditions.table_contents.1.content4") }}<br /><br />
            <i18next path="terms_conditions.table_contents.1.content5">
              <b>
                {{ $t("terms_conditions.table_contents.1.content5_0") }}
              </b>
              <b>
                {{ $t("terms_conditions.table_contents.1.content5_1") }}
              </b>
              <b>
                {{ $t("terms_conditions.table_contents.1.content5_2") }}
              </b>
              <sup>
                {{ $t("terms_conditions.table_contents.1.content5_3") }}
              </sup> </i18next
            ><br /><br />
            {{ $t("terms_conditions.table_contents.1.content6") }}<br /><br />
            <a target="_blank" href="https://www.who.int/data/principles">
              {{ $t("terms_conditions.table_contents.1.content7") }}</a
            ><br />
            <a
              target="_blank"
              href="https://www.who.int/publishing/datapolicy/en/"
            >
              {{ $t("terms_conditions.table_contents.1.content8") }}</a
            ><br />
            <a
              target="_blank"
              href="http://www.who.int/publishing/datapolicy/en/"
            >
              {{ $t("terms_conditions.table_contents.1.content9") }}</a
            ><br />
            <a
              target="_blank"
              href="https://intranet.who.int/homes/cre/ethics/disclosure/"
            >
              {{ $t("terms_conditions.table_contents.1.content10") }}</a
            ><br /><br />
            {{ $t("terms_conditions.table_contents.1.content11") }}<br /><br />
            {{ $t("terms_conditions.table_contents.1.content12") }}<br /><br />
            {{ $t("terms_conditions.table_contents.1.content13") }}
          </div>
        </div>
        <div id="item2-1" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.2.heading") }}
          </h2>
          <div class="item-body">
            {{ $t("terms_conditions.table_contents.2.content1") }}<br /><br />
            <table class="content-table">
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading1") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content1") }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading2") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content2") }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading3") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content3")
                  }}<br /><br />
                  {{ $t("terms_conditions.table_contents.2.table.content3_0") }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading4") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content4") }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading5") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content5") }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading6") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content6") }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading7") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content7") }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading8") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content8") }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading9") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content9") }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading10") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content10") }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading11") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content11") }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading12") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content12") }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading13") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content13") }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading14") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content14") }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading15") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content15") }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.heading16") }}
                </td>
                <td>
                  {{ $t("terms_conditions.table_contents.2.table.content16") }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div id="item2-2" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.3.heading") }}
          </h2>
          <div class="item-body">
            <ol>
              <li>
                {{ $t("terms_conditions.table_contents.3.list1") }}
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.3.list2") }}
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.3.list3") }}
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.3.list4") }}
                <ol type="a">
                  <li>
                    {{ $t("terms_conditions.table_contents.3.list5") }}
                  </li>
                  <li>
                    {{ $t("terms_conditions.table_contents.3.list6") }}
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
        <div id="item2-3" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.4.heading") }}
          </h2>
          <div class="item-body">
            <ol type="A">
              <li>
                {{ $t("terms_conditions.table_contents.4.list_items.1.heading")
                }}<br /><br />
                <ul>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.1.content1"
                      )
                    }}<br /><br />
                    <ol type="i">
                      <li>
                        <i18next
                          path="terms_conditions.table_contents.4.list_items.1.content2"
                        >
                          <b>
                            {{
                              $t(
                                "terms_conditions.table_contents.4.list_items.1.content2_0"
                              )
                            }}
                          </b> </i18next
                        ><br /><br />
                        {{
                          $t(
                            "terms_conditions.table_contents.4.list_items.1.content3"
                          )
                        }}
                      </li>
                      <li>
                        <i18next
                          path="terms_conditions.table_contents.4.list_items.1.content4"
                        >
                          <b>
                            {{
                              $t(
                                "terms_conditions.table_contents.4.list_items.1.content4_0"
                              )
                            }}
                          </b>
                        </i18next>
                      </li>
                      <li>
                        <i18next
                          path="terms_conditions.table_contents.4.list_items.1.content5"
                        >
                          <b>
                            {{
                              $t(
                                "terms_conditions.table_contents.4.list_items.1.content5_0"
                              )
                            }}
                          </b>
                        </i18next>
                      </li>
                      <li>
                        <i18next
                          path="terms_conditions.table_contents.4.list_items.1.content6"
                        >
                          <b>
                            {{
                              $t(
                                "terms_conditions.table_contents.4.list_items.1.content6_0"
                              )
                            }}
                          </b>
                        </i18next>
                      </li>
                      <li>
                        <i18next
                          path="terms_conditions.table_contents.4.list_items.1.content7"
                        >
                          <b>
                            {{
                              $t(
                                "terms_conditions.table_contents.4.list_items.1.content7_0"
                              )
                            }}
                          </b>
                        </i18next>
                      </li>
                      <li>
                        <i18next
                          path="terms_conditions.table_contents.4.list_items.1.content8"
                        >
                          <b>
                            {{
                              $t(
                                "terms_conditions.table_contents.4.list_items.1.content8_0"
                              )
                            }}
                          </b>
                        </i18next>
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.4.list_items.2.heading")
                }}<br /><br />
                <ul>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.2.content1"
                      )
                    }}<br /><br />
                    <ol type="i">
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.4.list_items.2.content2"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.4.list_items.2.content3"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.4.list_items.2.content4"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.4.list_items.2.content5"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.4.list_items.2.content6"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.4.list_items.2.content7"
                          )
                        }}
                      </li>
                    </ol>
                  </li>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.2.content8"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.2.content9"
                      )
                    }}
                  </li>
                </ul>
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.4.list_items.3.heading")
                }}<br /><br />
                <ul>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.3.content1"
                      )
                    }}<br /><br />
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.3.content2"
                      )
                    }}
                  </li>
                </ul>
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.4.list_items.4.heading")
                }}<br /><br />
                <ul>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.4.content1"
                      )
                    }}
                    <ol type="i">
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.4.list_items.4.content2"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.4.list_items.4.content3"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.4.list_items.4.content4"
                          )
                        }}
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.4.list_items.5.heading")
                }}<br /><br />
                <ul>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.5.content1"
                      )
                    }}
                  </li>
                </ul>
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.4.list_items.6.heading")
                }}<br /><br />
                <ul>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.6.content1"
                      )
                    }}
                  </li>
                </ul>
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.4.list_items.7.heading")
                }}<br /><br />
                <ul>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.7.content1"
                      )
                    }}
                  </li>
                </ul>
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.4.list_items.8.heading")
                }}<br /><br />
                <ul>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.8.content1"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.8.content2"
                      )
                    }}
                  </li>
                </ul>
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.4.list_items.9.heading")
                }}<br /><br />
                <ul>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.9.content1"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.9.content2"
                      )
                    }}<br /><br />
                    <ul>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.4.list_items.9.content3"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.4.list_items.9.content4"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.4.list_items.9.content5"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.4.list_items.9.content6"
                          )
                        }}
                      </li>
                    </ul>
                    <br />
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.9.content7"
                      )
                    }}<br /><br />
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.9.content8"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.9.content9"
                      )
                    }}
                  </li>
                </ul>
              </li>
              <li>
                {{
                  $t("terms_conditions.table_contents.4.list_items.10.heading")
                }}<br /><br />
                <ul>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.4.list_items.10.content1"
                      )
                    }}
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
        <div id="item2-4" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.5.heading") }}
          </h2>
          <div class="item-body">
            <ol>
              <li>
                {{ $t("terms_conditions.table_contents.5.content1") }}
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.5.content2") }}
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.5.content3") }}
                <ol type="i">
                  <li>
                    {{ $t("terms_conditions.table_contents.5.content4") }}
                  </li>
                  <li>
                    {{ $t("terms_conditions.table_contents.5.content5") }}
                  </li>
                  <li>
                    {{ $t("terms_conditions.table_contents.5.content6") }}
                  </li>
                  <li>
                    {{ $t("terms_conditions.table_contents.5.content7") }}
                  </li>
                </ol>
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.5.content8") }}
                <ol type="i">
                  <li>
                    <i18next path="terms_conditions.table_contents.5.content9">
                      <b>
                        {{ $t("terms_conditions.table_contents.5.content9_0") }}
                      </b>
                    </i18next>
                  </li>
                  <li>
                    <i18next path="terms_conditions.table_contents.5.content10">
                      <b>
                        {{
                          $t("terms_conditions.table_contents.5.content10_0")
                        }}
                      </b>
                    </i18next>
                  </li>
                  <li>
                    <i18next path="terms_conditions.table_contents.5.content11">
                      <b>
                        {{
                          $t("terms_conditions.table_contents.5.content11_0")
                        }}
                      </b>
                    </i18next>
                  </li>
                  <li>
                    <i18next path="terms_conditions.table_contents.5.content12">
                      <b>
                        {{
                          $t("terms_conditions.table_contents.5.content12_0")
                        }}
                      </b>
                    </i18next>
                  </li>
                  <li>
                    <i18next path="terms_conditions.table_contents.5.content13">
                      <b>
                        {{
                          $t("terms_conditions.table_contents.5.content13_0")
                        }}
                      </b>
                    </i18next>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
        <div id="item2-5" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.6.heading") }}
          </h2>
          <div class="item-body">
            <ol>
              <li>
                {{ $t("terms_conditions.table_contents.6.content1") }}
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.6.content2") }}
                <ol type="i">
                  <li>
                    {{ $t("terms_conditions.table_contents.6.content3") }}
                  </li>
                  <li>
                    {{ $t("terms_conditions.table_contents.6.content4") }}
                  </li>
                  <li>
                    {{ $t("terms_conditions.table_contents.6.content5") }}
                  </li>
                  <li>
                    {{ $t("terms_conditions.table_contents.6.content6") }}
                  </li>
                </ol>
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.6.content7") }}
                <ol type="i">
                  <li>
                    {{ $t("terms_conditions.table_contents.6.content8") }}
                  </li>
                  <li>
                    {{ $t("terms_conditions.table_contents.6.content9") }}
                  </li>
                </ol>
                {{ $t("terms_conditions.table_contents.6.content10") }}
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.6.content11") }}
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.6.content12") }}
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.6.content13") }}
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.6.content14") }}
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.6.content15") }}
              </li>
            </ol>
          </div>
        </div>
        <div id="item2-6" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.7.heading") }}
          </h2>
          <div class="item-body">
            {{ $t("terms_conditions.table_contents.7.content1") }}<br /><br />
            <ol type="A">
              <li>
                <i>{{
                  $t("terms_conditions.table_contents.7.list_items.1.heading")
                }}</i
                ><br />
                {{
                  $t("terms_conditions.table_contents.7.list_items.1.content1")
                }}
              </li>
              <li>
                <i>{{
                  $t("terms_conditions.table_contents.7.list_items.2.heading")
                }}</i
                ><br />
                {{
                  $t("terms_conditions.table_contents.7.list_items.2.content1")
                }}
              </li>
              <li>
                <i>{{
                  $t("terms_conditions.table_contents.7.list_items.3.heading")
                }}</i
                ><br />
                {{
                  $t("terms_conditions.table_contents.7.list_items.3.content1")
                }}
              </li>
              <li>
                <i>{{
                  $t("terms_conditions.table_contents.7.list_items.4.heading")
                }}</i
                ><br />
                {{
                  $t("terms_conditions.table_contents.7.list_items.4.content1")
                }}
                <ol type="i">
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.7.list_items.4.content2"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.7.list_items.4.content3"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.7.list_items.4.content4"
                      )
                    }}
                  </li>
                </ol>
              </li>
              <li>
                <i>{{
                  $t("terms_conditions.table_contents.7.list_items.5.heading")
                }}</i
                ><br />
                {{
                  $t("terms_conditions.table_contents.7.list_items.5.content1")
                }}
              </li>
              <li>
                <i>{{
                  $t("terms_conditions.table_contents.7.list_items.6.heading")
                }}</i
                ><br />
                {{
                  $t("terms_conditions.table_contents.7.list_items.6.content1")
                }}
              </li>
              <li>
                <i>{{
                  $t("terms_conditions.table_contents.7.list_items.7.heading")
                }}</i
                ><br />
                {{
                  $t("terms_conditions.table_contents.7.list_items.7.content1")
                }}
              </li>
              <li>
                <i>{{
                  $t("terms_conditions.table_contents.7.list_items.8.heading")
                }}</i
                ><br />
                {{
                  $t("terms_conditions.table_contents.7.list_items.8.content1")
                }}
              </li>
            </ol>
          </div>
        </div>
        <div id="item2-7" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.8.heading") }}
          </h2>
          <div class="item-body">
            <ol type="A">
              <li>
                {{ $t("terms_conditions.table_contents.8.list_items.1.heading")
                }}<br />
                <ol>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.8.list_items.1.content1"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.8.list_items.1.content2"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.8.list_items.1.content3"
                      )
                    }}
                    <ol type="a">
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.1.content4"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.1.content5"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.1.content6"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.1.content7"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.1.content8"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.1.content9"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.1.content10"
                          )
                        }}
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.8.list_items.2.heading")
                }}<br />
                <ol>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.8.list_items.2.content1"
                      )
                    }}
                    <ol type="a">
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.2.content2"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.2.content3"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.2.content4"
                          )
                        }}
                      </li>
                    </ol>
                  </li>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.8.list_items.2.content5"
                      )
                    }}
                    <ol type="a">
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.2.content6"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.2.content7"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.2.content8"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.2.content9"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.2.content10"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.2.content11"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.8.list_items.2.content12"
                          )
                        }}
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
        <div id="item2-8" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.9.heading") }}
          </h2>
          <div class="item-body">
            <ol type="A">
              <li>
                {{ $t("terms_conditions.table_contents.9.list_items.1.heading")
                }}<br />
                <ol>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.9.list_items.1.content1"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.9.list_items.1.content2"
                      )
                    }}
                    <ol type="a">
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.9.list_items.1.content3"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.9.list_items.1.content4"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.9.list_items.1.content5"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.9.list_items.1.content6"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.9.list_items.1.content7"
                          )
                        }}
                      </li>
                      <li>
                        {{
                          $t(
                            "terms_conditions.table_contents.9.list_items.1.content8"
                          )
                        }}
                      </li>
                    </ol>
                  </li>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.9.list_items.1.content9"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.9.list_items.1.content10"
                      )
                    }}
                  </li>
                </ol>
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.9.list_items.2.heading")
                }}<br />
                <ol>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.9.list_items.2.content1"
                      )
                    }}
                  </li>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.9.list_items.2.content2"
                      )
                    }}
                  </li>
                </ol>
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.9.list_items.3.heading")
                }}<br />
                <ol>
                  <li>
                    {{
                      $t(
                        "terms_conditions.table_contents.9.list_items.3.content1"
                      )
                    }}
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
        <div id="item2-9" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.10.heading") }}
          </h2>
          <div class="item-body">
            <ol>
              <li>
                {{ $t("terms_conditions.table_contents.10.content") }}
              </li>
            </ol>
          </div>
        </div>
        <div id="item2-10" class="content-item">
          <h2 class="item-heading">
            {{ $t("terms_conditions.table_contents.11.heading") }}
          </h2>
          <div class="item-body">
            <ol>
              <li>
                {{ $t("terms_conditions.table_contents.11.content1") }}
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.11.content2") }}
              </li>
              <li>
                {{ $t("terms_conditions.table_contents.11.content3") }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>
<script>
// Layouts
import LayoutManager from "@/components/Layout/LayoutManager";
import LandingPageLayout from "@/components/Layout/LandingPageLayout.vue";
import { LxpBreadcrumb } from "didactica";
import { mapGetters } from "vuex";

export default {
  name: "TermsConditionsPage",
  components: {
    LayoutManager,
    LandingPageLayout,
    LxpBreadcrumb
  },
  computed: {
    ...mapGetters(["isSystemAlertEnabled"]),
    isAuthenticated() {
      return this.$keycloak.authenticated;
    },
    breadcrumb1() {
      return [this.homeBreadcrumbPath, this.category1Path];
    },
    breadcrumb2() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.1.heading"),
          active: true
        }
      ];
    },
    breadcrumb3() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.2.heading"),
          active: true
        }
      ];
    },
    breadcrumb4() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.3.heading"),
          active: true
        }
      ];
    },
    breadcrumb5() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.4.heading"),
          active: true
        }
      ];
    },
    breadcrumb6() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.5.heading"),
          active: true
        }
      ];
    },
    breadcrumb7() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.6.heading"),
          active: true
        }
      ];
    },
    breadcrumb8() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.7.heading"),
          active: true
        }
      ];
    },
    breadcrumb9() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.8.heading"),
          active: true
        }
      ];
    },
    breadcrumb10() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.9.heading"),
          active: true
        }
      ];
    },
    breadcrumb11() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.10.heading"),
          active: true
        }
      ];
    },
    breadcrumb12() {
      return [
        this.homeBreadcrumbPath,
        this.category2Path,
        {
          icon: null,
          text: this.$t("terms_conditions.table_contents.11.heading"),
          active: true
        }
      ];
    },
    currentItem() {
      return this.$t("terms_conditions.category1");
    },
    navItems() {
      return [
        {
          id: "item1",
          name: this.$t("terms_conditions.category1"),
          subItems: [
            {
              id: "item1",
              name: this.$t("achievements.microcredential.disclaimer.title")
            }
          ]
        },
        {
          id: "item2",
          name: this.$t("terms_conditions.category2"),
          subItems: [
            {
              id: "item2",
              name: this.$t("terms_conditions.table_contents.1.heading")
            },
            {
              id: "item2-1",
              name: this.$t("terms_conditions.table_contents.2.heading")
            },
            {
              id: "item2-2",
              name: this.$t("terms_conditions.table_contents.3.heading")
            },
            {
              id: "item2-3",
              name: this.$t("terms_conditions.table_contents.4.heading")
            },
            {
              id: "item2-4",
              name: this.$t("terms_conditions.table_contents.5.heading")
            },
            {
              id: "item2-5",
              name: this.$t("terms_conditions.table_contents.6.heading")
            },
            {
              id: "item2-6",
              name: this.$t("terms_conditions.table_contents.7.heading")
            },
            {
              id: "item2-7",
              name: this.$t("terms_conditions.table_contents.8.heading")
            },
            {
              id: "item2-8",
              name: this.$t("terms_conditions.table_contents.9.heading")
            },
            {
              id: "item2-9",
              name: this.$t("terms_conditions.table_contents.10.heading")
            },
            {
              id: "item2-10",
              name: this.$t("terms_conditions.table_contents.11.heading")
            }
          ]
        }
      ];
    },
    homeBreadcrumbPath() {
      return {
        icon: null,
        text: this.$t("general.menu.home"),
        to: { name: "Home" }
      };
    },
    category1Path() {
      return {
        icon: null,
        text: this.$t("terms_conditions.category1"),
        active: true
      };
    },
    category2Path() {
      return {
        icon: null,
        text: this.$t("terms_conditions.category2"),
        active: true
      };
    }
  },
  data() {
    return {
      disclaimerIcon: require("@/assets/images/landing/information.svg"),
      isDesktop: false,
      isTab: false,
      isMobile: false,
      openSubMenus: {}
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.onResize();
    });
    this.onResize();

    if (this.isDesktop || this.isTab) {
      this.navItems.forEach(item => {
        this.$set(this.openSubMenus, item.id, true);
      });
    }

    if (this.isMobile) {
      const observer = new MutationObserver(() => {
        let activeLink;
        if (this.isDesktop || this.isTab) {
          activeLink = document.querySelector("#terms-list .nav-link.active");
        } else {
          activeLink = document.querySelector(
            "#mobile-nav .dropdown-item.active"
          );
        }
        if (activeLink) {
          this.currentItem = activeLink.textContent.trim();
        }
      });
      observer.observe(document.body, {
        attributes: true,
        subtree: true,
        attributeFilter: ["class"]
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.onResize();
    });
  },
  methods: {
    setCurrentItem(item) {
      this.currentItem = item;
    },
    navigateToLink(item) {
      this.$router.push(item.to);
    },
    scrollToContent(id, name) {
      this.setCurrentItem(name);
      const headerHeight = this.isAuthenticated
        ? document.getElementById("user-fluid-layout__top")?.offsetHeight
        : document.getElementById("lp-header")?.offsetHeight;
      const mobileNavHeight = document.getElementById("mobile-nav")
        ?.offsetHeight;
      const totalTopHeight = headerHeight + mobileNavHeight;
      const element = document.getElementById(id);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition - totalTopHeight + window.scrollY;
        window.scrollTo({
          top: offsetPosition
        });
      }
    },
    toggleMenu(id) {
      this.$set(this.openSubMenus, id, !this.openSubMenus[id]);
    },
    isSubMenuOpen(id) {
      return !!this.openSubMenus[id];
    },
    onResize() {
      this.isDesktop = window.innerWidth > 991;
      this.isTab = window.innerWidth > 767 && window.innerWidth < 992;
      this.isMobile = window.innerWidth < 768;
      this.mobileNavTop();
      if (this.isAuthenticated) {
        this.updatePanelStyle();
      }
    },
    mobileNavTop() {
      let topMargin = document.getElementById("lp-header")?.offsetHeight;
      document.getElementById("mobile-nav").style.top = topMargin + "px";
    },
    updatePanelStyle() {
      this.$nextTick(() => {
        const headerHeight = document.getElementById("user-fluid-layout__top")
          ?.offsetHeight;
        const breadcrumbHeight = document.getElementById("top-breadcrumb")
          ?.offsetHeight;
        const totalOffset = headerHeight + 2;
        const totalHeight = totalOffset + breadcrumbHeight;
        const leftContentElement = document.getElementById("terms-list");
        const rightContentElements = document.querySelectorAll(
          ".terms__conditions--container .content__item--container > .content-item"
        );
        const termsAndConditionsPage = document.getElementById("terms-page");

        if (leftContentElement) {
          leftContentElement.style.top = `${totalOffset}px`;
          leftContentElement.style.height = `calc(100vh - ${totalHeight}px)`;
        }
        if (rightContentElements.length > 0) {
          rightContentElements.forEach(element => {
            element.style.minHeight = `calc(100vh - ${headerHeight}px)`;
          });
        }
        if (termsAndConditionsPage) {
          termsAndConditionsPage.style.marginTop = `${headerHeight}px`;
        }
      });
    }
  },
  watch: {
    "$route.name"() {
      this.updatePanelStyle();
    },
    isSystemAlertEnabled() {
      setTimeout(() => {
        this.mobileNavTop();
        if (this.isAuthenticated) {
          this.updatePanelStyle();
        }
      }, 100);
    }
  }
};
</script>
<style scoped lang="scss">
.terms__conditions--container {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-family: $font-family;
  &.container {
    max-width: 1280px;
  }
  > .lxp-breadcrumb {
    width: 100%;
    padding-bottom: 16px;
    margin-bottom: 0;
  }
  .heading__item--container {
    border-right: 1px solid $brand-neutral-200;
    background-color: $brand-primary-75;
    padding: 24px;
    .nav {
      display: flex;
      flex-direction: column;
      width: 100%;
      > div {
        > .nav-link {
          display: flex;
          justify-content: space-between;
        }
      }
      > .nav-link {
        margin-top: 4px;
      }
    }
    .nav-link {
      padding: 8px;
      border-radius: 4px;
      color: $brand-primary;
      word-break: break-word;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.1px;
      width: 100%;
      &:hover {
        background-color: $brand-primary-100;
      }
      &.active:not(.no-active) {
        color: $brand-neutral-0;
        background-color: $brand-primary;
      }
      + .nav {
        .nav-link {
          font-weight: 400;
          color: $brand-neutral-700;
          padding: 8px 24px;
          &.active {
            color: $brand-neutral-0;
            background-color: $brand-primary;
          }
        }
      }
      &.dropdown-toggle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $brand-neutral-900;
        &:after {
          display: none;
        }
        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    .navbar-nav {
      width: 100%;
      .nav-item.dropdown {
        position: relative;
        &.show {
          .dropdown-toggle {
            color: $brand-primary;
            img {
              transform: rotate(180deg);
            }
          }
        }
      }
      .dropdown-menu {
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        width: 100%;
        margin: 0;
        border: none;
        border-radius: 8px;
        background-color: $brand-primary-75;
        padding: 8px;
        height: 300px;
        overflow-y: scroll;
        &::-webkit-scrollbar-track {
          border-radius: 8px;
          border: none;
          background-color: $brand-neutral-75;
        }
        &::-webkit-scrollbar {
          width: 4px;
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          border: none;
          background-color: $brand-neutral-300;
        }
        .dropdown-item {
          border-radius: 4px;
          padding: 8px;
          color: $brand-primary;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0.1px;
          word-break: break-word;
          text-wrap: wrap;
          &.active {
            color: $brand-neutral-0;
            background-color: $brand-primary;
          }
        }
        .subdropdown-menu {
          list-style-type: none;
          margin: 0;
          padding: 0;
          li {
            margin-top: 4px;
          }
          .dropdown-item {
            padding: 8px 8px 8px 24px;
            font-weight: 400;
            color: $brand-neutral-700;
            &.active {
              color: $brand-neutral-0;
              background-color: $brand-primary;
            }
          }
        }
      }
    }
  }
  .content__item--container {
    position: relative;
    padding: 0px 56px 56px;
    .disclaimer-information {
      display: flex;
      align-items: flex-start;
      padding: 8px 16px;
      border-radius: 16px;
      background-color: $brand-primary-100;
      margin-bottom: 16px;
      @include body-medium;
      color: $brand-neutral-700;
      img {
        margin-right: 16px;
      }
    }
    .content-item {
      padding-top: 16px;
    }
    .main-heading {
      color: $brand-primary;
      font-size: 36px;
      font-weight: 400;
      line-height: 43.2px;
      margin-bottom: 16px;
    }
    .item-heading {
      color: $brand-neutral-1000;
      font-size: 24px;
      font-weight: 400;
      line-height: 28.8px;
      margin-bottom: 16px;
    }
    .item-body {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.25px;
      word-break: break-word;
      text-wrap: wrap;
      color: $brand-neutral-1000;
      ul,
      ol {
        li {
          margin-top: 8px;
        }
      }
      ul {
        list-style-type: none;
      }
    }
    table {
      tr {
        &:first-child {
          td {
            border-top: none;
          }
        }
        td {
          color: $brand-neutral-900;
          border: 1px solid $brand-neutral-200;
          &:first-child {
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0.15px;
            padding: 8px;
            border-left: none;
            width: 200px;
          }
          &:last-child {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.25px;
            padding: 8px 24px;
            border-right: none;
          }
        }
      }
    }
  }
  .mobile__nav--wrapper {
    width: 100%;
    height: 56px;
    background-color: $brand-primary-50;
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    position: fixed;
    top: 64px;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }
  &.loggedin-terms {
    width: calc(100% - $ufl-sidenav-expand-width);
    margin-left: $ufl-sidenav-expand-width;
    padding-top: 2px;
    padding-left: 48px;
    padding-right: 48px;
    position: relative;
    background-color: $brand-primary-50;
    .heading__item--container {
      width: 260px;
      padding: 24px 16px;
      border-top-left-radius: 8px;
      box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    }
    .content__item--container {
      width: calc(100% - 260px);
      padding: 0px 32px;
      border-top-right-radius: 8px;
      background-color: $brand-neutral-0;
      box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    }
  }
  :deep {
    .lxp-breadcrumb {
      font-family: $font-family;
      margin-bottom: 16px;
      .breadcrumb {
        padding: 0;
        margin: 24px 0px 0px;

        .breadcrumb-item {
          a {
            @include body-medium;
            color: $brand-neutral-700;
          }

          &.active {
            a {
              color: $brand-primary;
              font-weight: 500;
            }
          }

          + .breadcrumb-item {
            &:before {
              position: relative;
              top: 2px;
              color: $brand-neutral-700;
            }
          }
        }
      }
    }
    > .lxp-breadcrumb {
      .breadcrumb {
        margin-top: 0px;
      }
    }
  }
}
.user-fluid-layout__side-nav-wrapper {
  &:not(.expand) {
    + .loggedin-terms {
      width: calc(100% - ($ufl-sidenav-default-width + 20px));
      margin-left: $ufl-sidenav-default-width + 20px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .terms__conditions--container {
    justify-content: flex-end;

    .heading__item--container {
      position: sticky;
      top: 64px;
      left: 0;
      width: 380px;
      height: calc(100vh - 64px);
      overflow-y: scroll;

      &::-webkit-scrollbar-track {
        border-radius: 8px;
        border: none;
        background-color: $brand-primary-75;
      }

      &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: none;
        background-color: $brand-neutral-200;
      }
    }

    .content__item--container {
      width: calc(100% - 380px);

      .content-item {
        min-height: calc(100vh - 104px);
      }
    }
  }

  .system-alert {
    .terms__conditions--container {
      .heading__item--container {
        top: 104px;
        height: calc(100vh - 104px);
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .terms__conditions--container {
    .heading__item--container {
      width: 260px;
      padding: 24px 16px;
    }

    .content__item--container {
      width: calc(100% - 260px);
      padding: 0px 32px 32px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .terms__conditions--container {
    flex-direction: column;
    padding-top: 0;
    overflow: hidden;

    .heading__item--container {
      width: 100%;
      padding: 0;
      border: none;
      border-radius: 4px;

      .nav-item.dropdown {
        margin: 0 !important;
      }

      .nav-link {
        &:hover {
          background-color: transparent;
        }
        &.dropdown-toggle {
          background-color: $brand-primary-100;
          margin: 0 !important;
        }
      }
    }

    .content__item--container {
      width: 100%;
      padding: 56px 0px 16px;

      .disclaimer-information {
        img {
          margin-right: 8px;
        }
      }

      table tr td {
        &:first-child {
          width: 35%;
        }
      }
    }
  }
}

[dir="rtl"] {
  .terms__conditions--container {
    text-align: right;
    .content__item--container {
      .disclaimer-information {
        img {
          margin-right: 0px;
          margin-left: 16px;
        }
      }
    }
    &.loggedin-terms {
      margin-left: 0;
      margin-right: $ufl-sidenav-expand-width;
      .heading__item--container {
        border-top-left-radius: 0px;
        border-top-right-radius: 8px;
      }
      .content__item--container {
        border-top-right-radius: 0px;
        border-top-left-radius: 8px;
      }
    }
  }
  .user-fluid-layout__side-nav-wrapper {
    &:not(.expand) {
      + .loggedin-terms {
        margin-left: 0px;
        margin-right: $ufl-sidenav-default-width + 20px;
      }
    }
  }
}
</style>
